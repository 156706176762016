import * as THREE from "three";

const _defineFunctionIfMissing = (obj, funName, fun) => {
    if (!obj.hasOwnProperty(funName)) {
        obj[funName] = fun;
    }
}

// Backport from R86: https://github.com/mrdoob/three.js/commit/e2f465d2fbf504c08f78a3653962b1caf7cd60e1
_defineFunctionIfMissing(THREE.Camera.prototype, "updateMatrixWorld", function ( force ) {

    THREE.Object3D.prototype.updateMatrixWorld.call( this, force );

    this.matrixWorldInverse.getInverse( this.matrixWorld );

});

_defineFunctionIfMissing(THREE, "WebGLProperties", function WebGLProperties() {
	function get() { return {}; }
	return { get };
});